<template>
  <div>
    <p></p>

    <v-row justify="center">
      <a class="text-h4 spacing-playground pa-4"></a>
    </v-row>
    <v-row justify="center" v-if="noschedule != ''">
      <a
        class="text-h3 red--text spacing-playground pa-6"
        @click="clickundefined(1)"
      >
        <strong>{{ noschedule }}</strong>
      </a>
    </v-row>
    <v-row justify="center" v-if="ambiguous != ''">
      <a class="text-h4 spacing-playground pa-4"></a>
    </v-row>
    <v-row justify="center" v-if="ambiguous != ''">
      <a
        class="text-h3 red--text spacing-playground pa-6"
        @click="clickschedule()"
      >
        <strong>{{ ambiguous }}</strong>
      </a>
    </v-row>
    <v-row justify="center" v-if="nextmonth != ''">
      <a class="text-h4 spacing-playground pa-3"></a>
    </v-row>
    <v-row justify="center" v-if="nextmonth != ''">
      <a
        class="text-h3 spacing-playground pa-6"
        color="Link"
        @click="clickundefined(3)"
      >
        <strong>{{ nextmonth }}</strong>
      </a>
    </v-row>
    <v-row justify="center" v-if="holdschedule != ''">
      <a class="text-h4 spacing-playground pa-3"></a>
    </v-row>
    <v-row justify="center" v-if="holdschedule != ''">
      <a
        class="text-h3 spacing-playground pa-4"
        color="Link"
        @click="clickundefined(2)"
      >
        <strong>{{ holdschedule }}</strong>
      </a>
    </v-row>
    <v-row justify="center" v-if="ternmoverflag != ''">
      <a
        class="text-h3 red--text spacing-playground pa-1"
        @click="clickundefined(2)"
      >
        <strong>{{ ternmoverflag }}</strong>
      </a>
    </v-row>
    <v-row justify="center" v-if="nextcycle != ''">
      <a class="text-h4 spacing-playground pa-3"></a>
    </v-row>
    <v-row justify="center" v-if="nextcycle != ''">
      <a
        class="text-h3 orange--text spacing-playground pa-6"
        @click="clickundefined(4)"
      >
        <strong>{{ nextcycle }}</strong>
      </a>
    </v-row>
    <v-row justify="center" v-if="otherschedule != ''">
      <a class="text-h4 spacing-playground pa-3"></a>
    </v-row>
    <v-row justify="center" v-if="otherschedule != ''">
      <a
        class="text-h3 spacing-playground pa-6"
        color="Link"
        @click="clickundefined(5)"
      >
        <strong>{{ otherschedule }}</strong>
      </a>
    </v-row>
    <v-row justify="center" v-if="noservice != ''">
      <a class="text-h4 spacing-playground pa-3"></a>
    </v-row>
    <v-row justify="center" v-if="noservice != ''">
      <a
        class="text-h3 red--text spacing-playground pa-6"
        @click="clickundefined(0)"
      >
        <strong>{{ noservice }}</strong>
      </a>
    </v-row>
  </div>
</template>

<script>
//mixins
import Common from "@/mixins/common";
import ShowDialogs from "@/mixins/showDialogs";
import Api from "@/mixins/api";

const DATE = new Date();

export default {
  name: "Office",
  mixins: [Common, ShowDialogs, Api],
  data: () => ({
    noschedule: "",
    holdschedule: "",
    nextmonth: "",
    nextcycle: "",
    otherschedule: "",
    noservice: "",
    ambiguous: "",
    ternmoverflag: ""
  }),
  methods: {
    async clickundefined(index) {
      //this.$router.push({ name: "ServiceRecordMaintenance", params: "" });
      const paramspattern = [];
      for (let i = 0; i < 7; i++) {
        if (i == index) {
          paramspattern.push("1");
        } else {
          paramspattern.push("0");
        }
      }
      const param = {
        pattern: paramspattern[0],
        noschedule: paramspattern[1],
        holdschedule: paramspattern[2],
        nextmonth: paramspattern[3],
        nextcycle: paramspattern[4],
        otherschedule: paramspattern[5]
      };
      this.$router.push({ name: "Unfinished", params: param });
    },
    async clickschedule() {
      const param = {
        test: ""
      };

      this.$router.push({ name: "ScheduleMaintenance", params: param });
    },
    async getShiftMonth(year, month, pathparam) {
      console.log("getShiftMonth", year, month, pathparam);
      // 選択した所属を取得
      console.log("selected", this.selecthospital);
      this.affiliations = [];
      //this.packages.packagemeisais = [];
      this.packages = [];
      this.schedules = [];
      const param = {
        affiliations: "",
        targetmonth: "",
        scheduleflag: "1",
        unfinished: "1",
        pattern: "1",
        noschedule: "1",
        holdschedule: "1",
        nextmonth: "1",
        nextcycle: "1",
        otherschedule: "1",
        ambiguous: "1"
      };
      const encode = encodeURI(JSON.stringify(param));
      console.log("encode", encode);
      console.log("this.packages", this.packages);
      // API送信
      const result = await this.$get(
        this.Paths.schedule,
        pathparam + "=" + encode
      );

      console.log("api_result", result);
      if (result.length == 0) {
        //await this.$warning("該当するデータがありません。", "データ取得");
      } else {
        //return await this.setShiftCalendar(result, year, month);
        console.log("result", result);
        await this.setinfomation(result);
        return;
      }
    },
    async setinfomation(result) {
      const auth = this.$store.state.authority;
      const hospital = result.hedder;
      const noschedulecount = [0];
      const holdschedulecount = [0];
      const termendcount = [0];
      const nextmonthcount = [0];
      const nextcyclecount = [0];
      const otherschedulecount = [0];
      const noservicecount = [0];
      const ambiguouscount = [0];

      //病院単位情報のループ
      for (let i = 0; i < hospital.length; i++) {
        const packages = hospital[i].details;
        //パッケージ単位のループ
        for (let j = 0; j < packages.length; j++) {
          if (packages[j].packagemeisai.length == 0) {
            continue;
          }
          //type -- 0:予定日無し, 1:実績日無し, 2:仮予定日, 3:日程検討, 4:次月対応, 5:次回サイクル, 6:別日程--
          //予定日未入力
          //const check1 = packages[j].scheduledate;
          const check1 = packages[j].type == 0 ? "1" : "0";

          //日程検討
          //const check2 = packages[j].packagemeisai[0].workarea[0].processingschedulehold;--
          const check2 = packages[j].type == 3 ? "1" : "0";

          //日程検討超過フラグ
          const check21 = packages[j].ternmoverflag;
          //次月対応
          //const check3 = packages[j].packagemeisai[0].workarea[0].processingschedulenextmonth;
          const check3 = packages[j].type == 4 ? "1" : "0";

          //次サイクル
          //const check4 = packages[j].packagemeisai[0].workarea[0].processingschedulenextcycle;
          const check4 = packages[j].type == 5 ? "1" : "0";

          //別作業
          //const check5 = packages[j].packagemeisai[0].workarea[0].processingscheduleother;
          const check5 = packages[j].type == 6 ? "1" : "0";

          //作業日未登録
          //const check6 = packages[j].execflag;
          const check6 = packages[j].type == 1 ? "1" : "0";

          // 仮予定日
          //const check7 = packages[j].ambiguous;
          const check7 = packages[j].type == 3 ? "1" : "0";

          //if (check4 != null && check4 == "1") {
          console.log(packages[j].keyid);
          //}

          console.log("check1", check1);
          console.log("check2", check2);
          console.log("check21", check21);
          console.log("check3", check3);
          console.log("check4", check4);
          console.log("check5", check5);
          console.log("check6", check6);
          console.log("check7", check7);

          /*if (check1 == null) {
            if (check4 == null) {
              noschedulecount[0] = noschedulecount[0] + 1;
            } else {
              if (check5 == null || check5 == "0") {
                noservicecount[0] = noservicecount[0] + 1;
              } else if (check5 == "1") {
                noschedulecount[0] = noschedulecount[0] + 1;
              }
              //noservicecount[0] = noservicecount[0] + 1;
            }
          } else {
            noschedulecount[0] = noschedulecount[0] + 1;
          }*/

          //日程検討、次月対応、次サイクルを取得
          if (check2 != null && check2 == "1") {
            holdschedulecount[0] = holdschedulecount[0] + 1;
            if (check21 != null && check21 == "1") {
              termendcount[0] = termendcount[0] + 1;
            }
            continue;
          }
          if (check3 != null && check3 == "1") {
            nextmonthcount[0] = nextmonthcount[0] + 1;
            continue;
          }
          if (check4 != null && check4 == "1") {
            nextcyclecount[0] = nextcyclecount[0] + 1;
            continue;
          }
          //予定日が未設定をチェック
          if (check1 != null && check1 == "1") {
            noschedulecount[0] = noschedulecount[0] + 1;
            continue;
          }
          //別作業扱いのものを☑
          if (check5 != null && check5 == "1") {
            otherschedulecount[0] = otherschedulecount[0] + 1;
            //continue;
          }
          //実績未登録をチェック
          if (check6 == "1") {
            noservicecount[0] = noservicecount[0] + 1;
          }

          // 仮予定日
          if (check7 == "1") {
            ambiguouscount[0] = ambiguouscount[0] + 1;
          }
        }
      }

      if (noservicecount[0] > 0) {
        this.noservice = "実績が" + noservicecount[0] + "件、未登録です。";
      } else {
        this.noservice = "実績未登録のパッケージはありません。";
      }

      if (auth > 1) {
        if (noschedulecount[0] > 0) {
          this.noschedule =
            "予定日が" + noschedulecount[0] + "件、未登録です。";
        } else {
          this.noschedule = "未登録の予定日はありません。";
        }

        if (holdschedulecount[0] > 0) {
          this.holdschedule =
            "日程検討中の予定が" + holdschedulecount[0] + "件、未登録です。";
          if (termendcount[0] > 0) {
            this.ternmoverflag =
              "うち、期日超過している予定が" + termendcount[0] + "件あります。";
          }
        }

        if (nextmonthcount[0] > 0) {
          this.nextmonth =
            "次月作業の予定が" + nextmonthcount[0] + "件、未登録です。";
        }

        if (nextcyclecount[0] > 0) {
          this.nextcycle =
            "次回サイクル作業の予定が" + nextcyclecount[0] + "件あります。";
        }

        if (otherschedulecount[0] > 0) {
          this.otherschedule =
            "別作業となった予定が" + otherschedulecount[0] + "件、未登録です。";
        }
        if (ambiguouscount[0] > 0) {
          this.ambiguous =
            "仮の予定日状態の予定が" + ambiguouscount[0] + "件、あります。";
        }
      }
    }
  },
  async created() {
    console.log("created", this.date);

    this.$loading();

    try {
      const authority = this.$store.state.authority;
      //データを取得して件数を表示
      if (authority != null && Number(authority) > 1) {
        await this.getShiftMonth("", "", "query");
      }
    } finally {
      this.$unloading();
    }
  },
  mounted() {
    setInterval(() => {
      this.date = new Date();
    }, 500);
  }
};
</script>

<style></style>
